import request from '@/utils/request'

// 获取消息列表
export function getAnnounceList(data) {
  return request({
    url: 'announce/list',
    method: 'get',
    data,
  })
}

// 获取公告详细信息
export function getAnnounceInfo(data) {
  return request({
    url: `announce/${data.id}`,
    method: 'get',
    data,
  })
}

// 标记消息为已读（消息指type=1的消息）
export function getAnnounceRead(data) {
  return request({
    url: `announce/${data.id}`,
    method: 'post',
    data,
  })
}

// 批量删除消息
export function announceDelete(data) {
  return request({
    url: `announce`,
    method: 'DELETE',
    data,
  })
}

// 获取帮助信息
export function helpContent(data) {
  return request({
    url: 'helpContent/helpContent',
    method: 'get',
    data,
  })
}

// 关于我们
export function aboutUs(data) {
  return request({
    url: 'helpContent/aboutUs',
    method: 'get',
    data,
  })
}

// 全部设置为已读
export function setMessageAllRead(data) {
  return request({
    url: 'announce/messageAllRead',
    method: 'get',
    data,
  })
}

// 意见反馈文本框提示语
export function getMessageAlert(data) {
  return request({
    url: 'message/getMessageAlert',
    method: 'get',
    data,
  })
}
