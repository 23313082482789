<template>
  <div class="news">
    <div class="nav-title">
      <i></i>首页— 消息中心
    </div>
    <div class="content">
      <div class="title">
        <div>
          <span :class="[{active: titleCheckedKey == item.key}]" v-for="item in titleObj" :key="item.key" @click="onChangeTab(item.key)">{{item.text}}</span>
        </div>

        <div>
          <span class="button-primary" @click="onRefresh">刷新</span>
          <span class="button-primary" @click="onBack">返回</span>
        </div>
      </div>
      <div class="list">
        <div class="top">
          <div class="left">
            <div class="input">
              <input type="text" placeholder="输入消息标题" v-model="inputVal" @keyup.enter="onSearch">
              <img src="../../assets/fangdajing.png" alt="" @click="onSearch">
            </div>

            <div class="news-status">消息状态
              <a-radio-group v-model="status" name="radioGroup" @change="onSearch">
                <a-radio 
                  v-for="item in statusOptions"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.text }}
                </a-radio>
              </a-radio-group>
            </div>
          </div>
          <div class="right">
            <div class="button-search" @click="onSearch">查询</div>
            <span class="button-primary" @click="onReset">重置</span>
          </div>
        </div>

        <a-table
          :columns="columns"
          :data-source="listData.rows"
          :row-key="record => record.id"
          :rowSelection="{
            type: 'checkbox',
            onChange: handleSelectNews,
            selectedRowKeys: checkedNewsList
          }"
          :pagination="{
            total: listData.total,
            current: pageNum,
            pageSize: pageSize,
            showTotal: () => `共${listData.total}条`,
            showSizeChanger: true,
          }"
          @change="handlePageChange"
        >
          <template slot="title">
            <span>{{ titleCheckedKey == 1 ? '消息列表' : '公告列表'}}</span>
            <div class="news-title" v-if="titleCheckedKey == 1">
              <span class="news-title-icon" @click="onAllDelete"><img src="@/assets/icon-delete.png" class="icon-read"/><i>全部删除</i></span>
              <span class="news-title-icon" @click="onAllRead"><img src="@/assets/icon-read.png" class="icon-read"/><i>全部设为已读</i></span>
            </div>
          </template>
      
          <template slot="status" slot-scope="text">
            <span>{{ text == 0 ? '未读' : '已读'}}</span>
          </template>
          <template slot="action" slot-scope="text, record">
            <p v-if="titleCheckedKey == 1" class="actionBox">
              <span @click="onDelete(record)">
                <img src="@/assets/icon-delete.png" /><i>删除</i>
              </span>
              <span v-if="record.status == 0" @click="onRead(record)">
                <img src="@/assets/icon-read.png" />
                <i>设为已读</i>
              </span>
            </p>
            <span v-if="titleCheckedKey == 2" @click="onSee(record)" style="cursor: pointer; align-items: center;">
              <img src="@/assets/icon-see.png" />
              查看
            </span>
          </template>
        </a-table>
      </div>
    </div>
    <HintOrDownModal
      title="提示"
      tipsType="tipsOne"
      :visible="deleteVisible"
      :handleClose="handleDeleteClose"
      :handleCallback="handleDeleteCallBack"
    />
  </div>
</template>

<script>
import { message } from 'ant-design-vue';
import { getAnnounceList, announceDelete, getAnnounceRead, setMessageAllRead } from '@/api/announce'
import HintOrDownModal from '@/components/HintOrDownModal.vue'

export default {
  components: { HintOrDownModal },
  data() {
    return {
      titleObj: [
        {
          key: 1,
          text: '消息中心'
        },
        {
          key: 2,
          text: '公告栏'
        }
      ],
      titleCheckedKey: 1,
      statusOptions: [{
          id: 1,
          text: '已读'
        } ,
        {
          id: 0,
          text: '未读'
        }
      ],
      status: '',
      columns: [
        {
          title: '序号',
          dataIndex: 'key',
        },
        {
          title: '标题',
          dataIndex: 'title',
        },
        {
          title: '时间',
          dataIndex: 'time',
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' }
        },
      ],
      listData: {
        rows: [],
        total: 0
      },
      pageNum: 1,
      pageSize: 10,
      inputVal: '',
      checkedNewsList: [],
      deleteVisible: false,
      deleteId: '',
      type: ''
    }
  },
  watch: {
    $route () {
      // 刷新记录上次的选中tab
      if (window.localStorage.getItem('newsOrAnnounceTabKey')) {
        this.titleCheckedKey = window.localStorage.getItem('newsOrAnnounceTabKey')
      }
      
      this.getNewsList()
    }
  },
  mounted() {
    // 刷新记录上次的选中tab
    if (window.localStorage.getItem('newsOrAnnounceTabKey')) {
      this.titleCheckedKey = window.localStorage.getItem('newsOrAnnounceTabKey')
    }
    
    this.getNewsList()
  },
  methods: {
    // 获取列表
    async getNewsList() {
      this.checkedNewsList = []
      const res = await getAnnounceList({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        type: this.titleCheckedKey,
        title: this.inputVal,
        status: this.status
      })

      res.rows.map((item, index)=> {
        item.key = index + 1
      })

      this.listData = res
    },
    // 消息中心和公告栏切换
    onChangeTab(key) {
      this.titleCheckedKey = key
      this.pageNum = 1
    
      this.getNewsList()

      window.localStorage.setItem('newsOrAnnounceTabKey', key)
    },
    // 分页
    handlePageChange(e) {
      this.pageNum = e.current
      this.pageSize = e.pageSize
      
      this.getNewsList()
    },
    // 搜索
    onSearch() {
      this.pageNum = 1
      this.getNewsList()
    },
    // 重置
    onReset() {
      this.status = ''
      this.inputVal = ''
      this.pageNum = 1
      this.getNewsList()
    },
    // 刷新
    onRefresh() {
      location.reload()
    },
    // 表格选中
    handleSelectNews(e) {
      this.checkedNewsList = e
    },
    // 删除消息
    async onDelete(record) {
      this.type = 'single'
      this.deleteId = record.id
      this.deleteVisible = true
    },
    // 批量删除
    async onAllDelete() {
      this.type = 'all'
      this.deleteVisible = true
    },
    handleDeleteClose() {
      this.deleteVisible = false
    },
    async handleDeleteCallBack() {
      if (this.type == 'single') {
        try {
          await announceDelete({
            id: this.deleteId
          })

          this.deleteVisible = false
          message.success('删除成功！');
          this.getNewsList()
        } catch (error) {
          console.log('error', error)
        }
      } else {
        try {
          let params = {
            title: this.inputVal,
            status: this.status,
          }

          if (this.checkedNewsList.length) {
            params.ids = this.checkedNewsList.join(',')
          }

          await announceDelete(params)

          this.deleteVisible = false
          message.success('删除成功！');
          this.getNewsList()
          
        } catch (error) {
          console.log('error', error)
        }
      }
    },
    // 返回
    onBack() {
      this.$router.go(-1)
    },
    // 跳转公告详情
    onSee(record) {
      this.$router.push(`/news-detail?type=announce&id=${record.id}`)
    },
    // 设置为已读
    async onRead(record) {
      try {
        await getAnnounceRead({
          id: record.id
        })
        message.success('设置成功！');

        this.getNewsList()
      } catch (error) {
        message.error(error.msg);
      }
    },
    // 全部设置为已读
    async onAllRead() {
      try {
        let params = {
          type: this.titleCheckedKey,
          title: this.inputVal,
          status: this.status,
        }

        if (this.checkedNewsList.length) {
          params.ids = this.checkedNewsList.join(',')
        }

        await setMessageAllRead(params)
        this.getNewsList()
        message.success('设置成功！');
      } catch (error) {
        message.success(error.msg);
      }
    }
  }
};
</script>

<style lang="less">
.news {
  width: 100%;
  background: #edf0f2;
  padding: 46px 0px 100px;
  box-sizing: border-box;

  .content {
    width: auto;
    padding: 0px 51px;

    .title {
      display: flex;
      justify-content: space-between;
      & > div {
        display: flex;
      }
      .button-primary {
        height: 34px !important;
        margin-right: 0;
        color: #6868CE;
      }
    }

    .list {
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      border: 1px solid #e6e6e6;
      margin-top: 10px;
      padding: 0px 26px 16px 26px;
      box-sizing: border-box;

      .top {
        width: 100%;
        height: 59px;
        border-bottom: 1px dashed #c6c4c4;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
          height: 20px;
          display: flex;
          .input {
            width: 190px;
            height: 32px;
            border: 1px solid #979797;
            position: relative;

            input {
              width: 100%;
              height: 100%;
              border: none;
              outline: none;
              padding: 3px 5px;

              :focus {
                outline: none;
              }
            }


            img {
              width: 19px;
              height: 19px;
              position: absolute;
              right: 13px;
              top: 6px;
              cursor: pointer;
            }
          }

          .news-status {
            display: flex;
            align-items: center;
            margin-left: 31px;
            margin-top: 10px;
            .ant-radio-group {
              margin-left: 10px;
            }
          }
        }

        .right {
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }

    .actionBox {
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        cursor: pointer;
        margin-right: 20px;
      }
      i {
        font-style: normal;
      }
    }
  }

  .news-title {
    display: flex;
    .news-title-icon {
      font-size: 14px !important;
      cursor: pointer;
      display: flex;
      align-items: center;
      i {
        font-style: normal;
        margin-top: 4px;
      }
      &:nth-of-type(1) {
        margin-right: 40px !important;
      }
    }

    .icon-read {
      width: 18px;
      margin-right: 6px;
      vertical-align: middle;
    }
  }
}
</style>
